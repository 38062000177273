import { create } from "zustand";
import Cookies from "js-cookie";
import axios from "axios";
import { CustomUser } from "@type/types";
import { persist, createJSONStorage } from "zustand/middleware";

type User = {
    id: string;
    email: string;
    [key: string]: any;
}

type AuthState = {
    isAuthenticated: boolean;
    user: User | null;
    authError: string | null;

    checkAuthenticated: () => Promise<void>;
    loadUser: () => Promise<void>;
    login: (email: string, password: string) => Promise<void>;
    signup: (formData: FormData) => Promise<void>;
    updateSchoolInfo: (id: string, formData: FormData, school_user: string, email: string) => Promise<void>;
    logout: () => void;
}

export const useAuthStore = create<AuthState>()(
    persist(
        (set) => ({
        isAuthenticated: false,
        user: null,
        authError: null,
    
        checkAuthenticated: async () => {
            try {
            const accessToken = Cookies.get("access");
            if (!accessToken) {
                set({ isAuthenticated: false });
                return;
            }
    
            const config = { headers: { Authorization: `JWT ${accessToken}` } };
            const body = { token: accessToken };
    
            const res = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/jwt/verify/`, body, config);
    
            if (res.data.code !== "token_not_valid") {
                set({ isAuthenticated: true });
            } else {
                set({ isAuthenticated: false });
            }
            } catch (error) {
            set({ isAuthenticated: false });
            }
        },
    
        loadUser: async () => {
            try {
            const accessToken = Cookies.get("access");
            if (!accessToken) {
                set({ user: null });
                return;
            }
    
            const config = { headers: { Authorization: `JWT ${accessToken}` } };
            const res = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/auth/users/me/`, config);
            set({ user: res.data });
            } catch (error) {
            set({ user: null });
            }
        },
    
        login: async (email, password) => {
            try {
            const body = { email, password };
            const res = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/jwt/create/`, body);
    
            Cookies.set("access", res.data.access, { expires: 1, sameSite: "strict", secure: true });
            Cookies.set("refresh", res.data.refresh, { expires: 2, sameSite: "strict", secure: true });
    
            set({ isAuthenticated: true });
            await useAuthStore.getState().loadUser();
            } catch (error) {
            set({ authError: "Invalid credentials" });
            throw error;
            }
        },
    
        signup: async (formData) => {
            const formDataObject = Object.fromEntries(formData.entries());
            let customUserId: CustomUser | undefined;
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const customUserBody = JSON.stringify({
                email: formDataObject.email, 
                password: formDataObject.password, 
                re_password: formDataObject.re_password,
                is_coach: formDataObject.is_coach === 'true',
                is_schoolAdmin: formDataObject.is_schoolAdmin === 'true',
            });
            try {
                const configSchool = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                try {
                    const res = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/users/`, customUserBody, config);
                    customUserId = res.data;
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response?.data) {
                        console.error("Signup Error:", error.response.data);
                        set({ authError: error.response.data }); // Store errors in Zustand
                    } else {
                        set({ authError: "An unexpected error occurred", isAuthenticated: false });
                    }
                }
                try {
                    if (!customUserId?.id) throw new Error("User ID missing!");
                    // Create School Data
                    const allowedKeys = new Set([
                        'school_type',
                        'school_name',
                        'address',
                        'district',
                        'province',
                        'phone_number',
                        'email',
                        'payment_options',
                        'cricket',
                        'rugby',
                        'netball',
                        'hockey',
                        'tennis',
                        'school_user',
                        'school_logo',
                    ]);
        
                    const schoolFormData = new FormData();
                    for (const [key, value] of formData.entries()) {
                        if (allowedKeys.has(key) && value) {
                            schoolFormData.append(key, value);
                        }
                    }
                    schoolFormData.append("school_user", customUserId.id.toString());
    
                    await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/school/create/`, schoolFormData, configSchool);
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response?.data) {
                        set({ authError: error.response.data });
                    } else {
                        set({ authError: "An unexpected error occurred", isAuthenticated: false });
                    }
                    throw error;
                }
            } catch (error) {
                if (customUserId?.id) {
                    await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/school/rollback/`, { user_id: customUserId.id }, config);
                }
                if (axios.isAxiosError(error) && error.response?.data) {
                    set({ authError: error.response.data, isAuthenticated: false });
                } else {
                    set({ authError: "An unexpected error occurred", isAuthenticated: false });
                }
                throw error;
            }
        },
    
        updateSchoolInfo: async (id, formData, school_user, email) => {
            try {
            formData.append("school_user", school_user);
            formData.append("email", email);
    
            const config = {
                headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${Cookies.get("access")}`,
                },
            };
    
            await axios.put(`${import.meta.env.VITE_BACKEND_URL}/api/school/update/${id}/`, formData, config);
            await useAuthStore.getState().loadUser();
            } catch (error) {
            throw error;
            }
        },
    
        logout: () => {
            Cookies.remove("access");
            Cookies.remove("refresh");
            set({ isAuthenticated: false, user: null });
        },
    }),
        {
            name: "auth", // Corrected name for localStorage key
            storage: createJSONStorage(() => sessionStorage), // Using sessionStorage as storage
        }
));
