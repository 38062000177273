import { SidebarProvider, SidebarTrigger } from "@ui/sidebar";
import { useInitializeApp } from "@hooks/useInitializeApp";
import LazyLoadingPage from "@components/LazyLoadingPage";
import { useSchoolInfo } from "@hooks/useQueryHooks";
import { Routes, Route } from "react-router";
import { SportData } from "@type/types";
import HomePage from "@pages/HomePage";
import { lazy, Suspense } from "react";

const ErrorPage = lazy(() => import("@pages/404page/ErrorPage"));
const DashBoardPage = lazy(() => import("@pages/dashboard/DashboardPage"));
const ScoringPage = lazy(() => import("@pages/scoring/ScoringPage"));
const RugbyScoringPage = lazy(
	() => import("@pages/scoring/Rugby/RugbyScoringPage")
);
const TennisScoringPage = lazy(
	() => import("@pages/scoring/Tennis/TennisScoringPage")
);
const NetballScoringPage = lazy(
	() => import("@pages/scoring/Netball/NetballScoringPage")
);
const CricketScoringPage = lazy(
	() => import("@pages/scoring/Cricket/CricketScoringPage")
);
const HockeyScoringPage = lazy(
	() => import("@pages/scoring/Hockey/HockeyScoringPage")
);

const LoginPage = lazy(() => import("@pages/login/LoginPage"));
const RegisterPage = lazy(() => import("@pages/register/RegisterPage"));
const ActivePage = lazy(
	() => import("@pages/activate/[id]/[token]/ActivePage")
);
const Activate = lazy(() => import("@pages/activate/Activate"));
const ResetPasswordConfirm = lazy(
	() =>
		import(
			"@pages/password/reset/confirm/[uid]/[token]/ResetPasswordConfirm"
		)
);

const About = lazy(() => import("@pages/about/About"));
const ContactUs = lazy(() => import("@pages/contactUs/Contact"));
const Terms = lazy(() => import("@pages/termsOfUse/Terms"));
const Policy = lazy(() => import("@pages/privacyPolicy/Policy"));
const Pricing = lazy(() => import("@pages/pricing/PricingPage"));
const Features = lazy(() => import("@pages/features/Features"));

function App() {
	const { data } = useSchoolInfo();

	useInitializeApp();

	const sportSelected: SportData = {
		cricket: data?.cricket,
		rugby: data?.rugby,
		netball: data?.netball,
		tennis: data?.tennis,
		hockey: data?.hockey,
	};

	return (
		<Routes>
			<Route
				path="/"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<HomePage />
					</Suspense>
				}
			/>
			<Route path="/scoring">
				<Route
					index
					element={
						<Suspense fallback={<LazyLoadingPage />}>
							<ScoringPage sportSelected={sportSelected} />
						</Suspense>
					}
				/>
				<Route
					path="rugby"
					element={
						<Suspense fallback={<LazyLoadingPage />}>
							<RugbyScoringPage />
						</Suspense>
					}
				/>
				<Route
					path="tennis"
					element={
						<Suspense fallback={<LazyLoadingPage />}>
							<TennisScoringPage />
						</Suspense>
					}
				/>
				<Route
					path="netball"
					element={
						<Suspense fallback={<LazyLoadingPage />}>
							<NetballScoringPage />
						</Suspense>
					}
				/>
				<Route
					path="cricket"
					element={
						<Suspense fallback={<LazyLoadingPage />}>
							<CricketScoringPage />
						</Suspense>
					}
				/>
				<Route
					path="hockey"
					element={
						<Suspense fallback={<LazyLoadingPage />}>
							<HockeyScoringPage />
						</Suspense>
					}
				/>
			</Route>
			<Route
				path="/login"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<LoginPage />
					</Suspense>
				}
			/>
			<Route
				path="/password/reset/confirm/:uid/:token"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<ResetPasswordConfirm />
					</Suspense>
				}
			/>
			<Route
				path="/activate/:uid/:token"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<ActivePage />
					</Suspense>
				}
			/>
			<Route
				path="/register"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<RegisterPage />
					</Suspense>
				}
			/>
			<Route
				path="/activate"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<Activate />
					</Suspense>
				}
			/>
			<Route
				path="/about-us"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<About />
					</Suspense>
				}
			/>
			<Route
				path="/contact-us"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<ContactUs />
					</Suspense>
				}
			/>
			<Route
				path="/terms-of-use"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<Terms />
					</Suspense>
				}
			/>
			<Route
				path="/privacy-policy"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<Policy />
					</Suspense>
				}
			/>
			<Route
				path="/pricing"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<Pricing />
					</Suspense>
				}
			/>
			<Route
				path="/features"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<Features />
					</Suspense>
				}
			/>
			<Route
				path="/dashboard/*"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<SidebarProvider>
							<SidebarTrigger />
							<DashBoardPage />
						</SidebarProvider>
					</Suspense>
				}
			/>

			<Route
				path="*"
				element={
					<Suspense fallback={<LazyLoadingPage />}>
						<ErrorPage />
					</Suspense>
				}
			/>
		</Routes>
	);
}

export default App;
