import { Event, EventFrontend } from "@type/Events";
import { combineDateAndTime } from "@utils/combineDate";
import axios from "axios";
import Cookie from 'js-cookie';

export async function getEvents(isAuthenticated: boolean): Promise<Event[] | null> {
    if (!isAuthenticated) {
        return null; // or you can return a specific type if required, e.g., {} or an error message
    }

    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/events/`, config)

    return response.data;
}

export async function getEvent(isAuthenticated: boolean, eventId: number): Promise<Event | null> {
    if (!isAuthenticated) {
        return null; // or you can return a specific type if required, e.g., {} or an error message
    }

    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/events/${eventId}`, config)

    return response.data;
}

export async function createEvent(isAuthenticated: boolean, data: EventFrontend): Promise<Event | undefined> {
    if (!isAuthenticated) {
        return; // or you can return a specific type if required, e.g., {} or an error message
    }
    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    // Combine date and time fields into datetime strings
    const startDateTime = combineDateAndTime(data.start_date, data.start_time)
    const endDateTime = combineDateAndTime(data.end_date, data.end_time)

    // Format for backend
    const formattedData = {
        name: data.name,
        sport_type: data.sport_type,
        age_group: data.age_group,
        homeTeam: data.homeTeam,
        awayTeam: data.awayTeam,
        school: data.school,
        description: data.description,
        location: data.location,
        start_time: startDateTime.toISOString(),
        end_time: endDateTime.toISOString(),
        // The school will be determined by the backend based on the authenticated user
    }

    try {
        const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/events/create/`, formattedData, config)
        return response.data
    } catch (err) {
        console.error(err);
    }
}

export async function updateEvent(isAuthenticated: boolean, eventId: string, data: EventFrontend): Promise<Event | undefined> {
    if (!isAuthenticated) {
        return; // or you can return a specific type if required, e.g., {} or an error message
    }

    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    };
 // Combine date and time fields into datetime strings
    const startDateTime = combineDateAndTime(data.start_date, data.start_time)
    const endDateTime = combineDateAndTime(data.end_date, data.end_time)

    // Format for backend
    const formattedData = {
        name: data.name,
        sport_type: data.sport_type,
        age_group: data.age_group,
        homeTeam: data.homeTeam,
        awayTeam: data.awayTeam,
        school: data.school,
        description: data.description,
        location: data.location,
        start_time: startDateTime.toISOString(),
        end_time: endDateTime.toISOString(),
        // The school will be determined by the backend based on the authenticated user
    }

    try {
        const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}/events/${eventId}/`, formattedData, config)
        return response.data
    } catch (err) {
        console.error(err);
    }
}

export async function deleteEvent(eventId: string): Promise<void> {    
    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/events/${eventId}/`, config);
}