// Utility to convert database date string to form date object
export const databaseToFormDate = (dateString: string) => {
    const date = new Date(dateString);
    return {
        day: date.getDate().toString().padStart(2, '0'),
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        year: date.getFullYear().toString()
    };
};

// Utility to convert form date object to database date string
export const formToDatabaseDate = (dateObj: { day: string; month: string; year: string }) => {
    return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
};