import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsHockeyIcon from "@mui/icons-material/SportsHockey";
import RugbyLaptop from "@images/RugbyLaptop.webp";
import NetballLaptop from "@images/NetballLaptop.webp";
import TennisLaptop from "@images/TennisLaptop.webp";
import HockeyLaptop from "@images/HockeyLaptop.webp";
import CricketLaptop from "@images/CricketLaptop.jpg";
import TennisMobile from "@images/TennisMobile.webp";
import CricketMobile from "@images/CricketMobile.webp";
import HockeyMobile from "@images/HockeyMobile.webp";
import NetballMobile from "@images/NetballMobile.webp";
import RugbyMobile from "@images/RugbyMobile.webp";
import MobileApp from "@images/ScoringWizMobile.webp";
import { SchoolType } from "@type/types";

const AGES = ["U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19"]

const sportTypes = [
    { name: "Cricket", icon: SportsCricketIcon, mobileImage: CricketMobile, desktopImage: CricketLaptop, appImage: MobileApp,},
    { name: "Netball", icon: SportsBasketballIcon, mobileImage: NetballMobile, desktopImage: NetballLaptop, appImage: MobileApp, },
    { name: "Rugby", icon: SportsRugbyIcon, mobileImage: RugbyMobile, desktopImage: RugbyLaptop, appImage: MobileApp, },
    { name: "Tennis", icon: SportsTennisIcon, mobileImage: TennisMobile, desktopImage: TennisLaptop, appImage: MobileApp, },
    { name: "Hockey", icon: SportsHockeyIcon, mobileImage: HockeyMobile, desktopImage: HockeyLaptop, appImage: MobileApp, },
] as const

const provinces = [
    'Gauteng',
    'Limpopo',
    'Mpumalanga',
    'North West',
    'Free State',
    'Western Cape',
    'Eastern Cape',
    'Northern Cape',
    'KwaZulu-Natal'
]

const schoolTypes: SchoolType[] = [
    'Primary',
    'Laerskool',
    'High School',
    'Hoërskool',
    'College',
]

const raceChoices = [
    'White',
    'Black',
    'Indian',
    'Coloured',
    'Other'
]

export { sportTypes, provinces, schoolTypes, raceChoices, AGES }