import { NetballSchoolStats, NetballStatsFetch } from "@type/NetballTypes";
import { RugbyStats, RugbyStatsFetch } from "@type/RugbyTypes";
import Cookie from "js-cookie";
import axios from "axios";


type StatsFetchResponse = RugbyStatsFetch | NetballStatsFetch;

const fetchStats = async (id: string): Promise<StatsFetchResponse | undefined> => {
    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${accessToken}`,
        },
    };

    if (id === 'tennis') {
        try {
            const response = await axios.get(
                `${import.meta.env.VITE_BACKEND_URL}/stats/athletes/${id}/stats/`,
                config
            );
            return response.data;
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    } else {
        try {
            const schoolStats = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/school/${id}/`, config);
            const playerStats = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/athletes/${id}/stats/`, config);

            if (id === "netball") {
                return {
                    schoolStats: schoolStats.data as NetballSchoolStats[],
                    playerStats: playerStats.data,
                };
            } else if (id === 'rugby') {
                return {
                schoolStats: schoolStats.data as RugbyStats[],
                playerStats: playerStats.data,
            };
            }

            return {schoolStats: schoolStats.data, playerStats: playerStats.data};
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }

}

export default fetchStats;