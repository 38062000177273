import { configureStore, combineReducers, Reducer, UnknownAction, Store } from '@reduxjs/toolkit';

// Static reducers
const staticReducers = {
};

// Define store type that includes reducer manager
interface ReducerManager {
    getReducerMap: () => Record<string, Reducer<any, UnknownAction>>;
    reduce: Reducer<any, UnknownAction>;
    add: (key: string, reducer: Reducer<any, UnknownAction>) => void;
    remove: (key: string) => void;
    replaceReducer: () => void;
}

export type StoreWithReducerManager = Store & {
    reducerManager: ReducerManager;
};

// Create reducer manager factory
const createReducerManager = (initialReducers: Record<string, Reducer<any, UnknownAction>>) => {
    const reducers = { ...initialReducers };
    let combinedReducer = combineReducers(reducers);
    let keysToRemove: string[] = [];

    const reducerManager: ReducerManager = {
        getReducerMap: () => reducers,

        reduce: (state: any, action: UnknownAction) => {
            if (keysToRemove.length > 0) {
                state = { ...state };
                for (const key of keysToRemove) {
                    delete state[key];
                }
                keysToRemove = [];
            }
            return combinedReducer(state, action);
        },

        add: (key: string, reducer: Reducer<any, UnknownAction>) => {
            if (!key || reducers[key]) {
                return;
            }

            reducers[key] = reducer;
            combinedReducer = combineReducers(reducers);
            store.replaceReducer(combinedReducer);
        },

        remove: (key: string) => {
            if (!key || !reducers[key]) {
                return;
            }

            delete reducers[key];
            keysToRemove.push(key);
            combinedReducer = combineReducers(reducers);
            store.replaceReducer(combinedReducer);
        },

        replaceReducer: () => {
            combinedReducer = combineReducers(reducers);
            store.replaceReducer(combinedReducer);
        },
    };

    return reducerManager;
};

const reducerManager = createReducerManager(staticReducers);

const store = configureStore({
    reducer: (state, action) => reducerManager.reduce(state, action), // Use the reduce method to handle actions
}) as StoreWithReducerManager;


// Attach the reducer manager to the store
store.reducerManager = reducerManager;

// Infer RootState type from store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;