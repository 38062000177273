import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useAuthStore } from "@store/useAuthStore";

export const useInitializeApp = () => {
    const { isAuthenticated, checkAuthenticated, loadUser } = useAuthStore((state) => state);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!isAuthenticated) {
            checkAuthenticated();
            loadUser();
        }
    }, [isAuthenticated, checkAuthenticated, loadUser]);

    return queryClient; // Optional: return QueryClient if needed
};