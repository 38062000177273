import { QuarterNumber } from '@type/NetballTypes';
import { AgeGroupType, TeamType } from '@type/types';
import { atom } from 'jotai';


interface LoginModalState {
    open: boolean;
    navLink: string;
}

export const selectSportsAtom = atom<string[]>([]);
export const billingCycleAtom = atom<'monthly' | 'annually'>('monthly');
export const loginModalAtom = atom<LoginModalState>({
    open: false,
    navLink: "",
})
export const liveAtom = atom<boolean>(false);
export const liveBtn = atom<boolean>(true);

export const hoursAtom = atom<number>(0);
export const minutesAtom = atom<number>(0);
export const secondsAtom = atom<number>(0);

export const selectedAgeAtom = atom<AgeGroupType>("")
export const selectedGenderAtom = atom<"Male" | "Female">(
    "Male"
);

export const quarterAmountAtom = atom<QuarterNumber>(2);
export const changeNetballBallAtom = atom<boolean>(false);

// Cricket Atoms
export const battingTeamAtom = atom<TeamType>("Home");
export const runningRunsAtom = atom<number>(0);
export const strikerAtom = atom<string>("");
export const nonStrikerAtom = atom<string>("");
export const bowlerAtom = atom<string>("");