import { CreateCoach } from '@type/addAthletes';
import Cookie from 'js-cookie';
import axios from 'axios';


async function coachListFunc() {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${Cookie.get("access")}`,
            Accept: "application/json",
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_BACKEND_URL}/api/coach/`,
            config
        );
        return response.data;
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message);
        }
    }
}

const handleCoachDelete = async (pk: number) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${Cookie.get("access")}`,
            Accept: "application/json",
        },
    };

    await axios.delete(
        `${import.meta.env.VITE_BACKEND_URL}/api/school/delete-user/${pk}/`,
        config
    );
};
const createCoach = async (data: CreateCoach, schoolId: number) => {
    const configUser = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${Cookie.get("access")}`,
            Accept: "application/json",
        },
    };
    const configAuth = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const email = data.email;
    const password = data.password;
    const re_password = data.re_password;
    const first_name = data.first_name;
    const last_name = data.last_name;

    try {
        const res = await axios.post(
            `${import.meta.env.VITE_BACKEND_URL}/auth/users/`,
            {
            email,
            password,
            re_password,
            is_coach: true,
            is_schoolAdmin: false,
            },
            configAuth
        );

        const userId = res.data.id;

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_BACKEND_URL}/api/coach/create/`,
            {
                first_name,
                last_name,
                school_coach: userId,
                school: schoolId,
                email,
            },
            configUser
        );
        return response.data; // Return the created data from the API response
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message);
        }
    }
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message || "Failed to create coach");
        } else {
            throw new Error("Failed to create coach");
        }
    }
};

export { coachListFunc, handleCoachDelete, createCoach };