import {
	IconButton,
	Avatar,
	Divider,
	SwipeableDrawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Button,
} from "@mui/material";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useSchoolInfo } from "@hooks/useQueryHooks";
import LogoutIcon from "@mui/icons-material/Logout";
import { memo, MouseEvent, useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router";

import ScoringWizLogo from "../assets/LogoScoringWizLogo.png";
import style from "../utils/utils.module.css";
import { useAuthStore } from "@store/useAuthStore";

function NavBar() {
	const navigate = useNavigate();
	const { isAuthenticated, logout } = useAuthStore((state) => state);
	const { data } = useSchoolInfo();
	const [anchorEl, setAnchorEl] = useState(false);
	const open = anchorEl;

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (e.currentTarget.id === "open_drawer") {
			setAnchorEl(true);
		}
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
		if (e.currentTarget.id === "close_drawer") {
			setAnchorEl(false);
		}
	};

	function authLogin(e: MouseEvent<HTMLElement>) {
		if (e.currentTarget.id === "logout") {
			logout();
			navigate("/");
		}
		if (isAuthenticated) {
			if (e.currentTarget.id === "dashboard") {
				navigate("/dashboard");
			} else if (e.currentTarget.id === "scoring") {
				navigate("/scoring");
			}
		} else {
			if (e.currentTarget.id === "scoring") {
				navigate("/scoring");
			} else if (e.currentTarget.id === "pricing") {
				navigate("/pricing");
			} else if (e.currentTarget.id === "features") {
				navigate("/features");
			} else {
				navigate("/login");
			}
		}
	}

	const homeClick = () => navigate("/");
	document.addEventListener("logo", (event) => event.preventDefault());

	const mobileScreen = () => {
		return (
			<List>
				<ListItem sx={{ display: { sm: "block", md: "none" } }}>
					<ListItemButton>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText onClick={homeClick}>Home</ListItemText>
					</ListItemButton>
				</ListItem>
				{isAuthenticated && (
					<ListItem sx={{ display: { sm: "block", md: "none" } }}>
						<ListItemButton>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText onClick={authLogin} id="dashboard">
								Dashboard
							</ListItemText>
						</ListItemButton>
					</ListItem>
				)}
				<ListItem sx={{ display: { sm: "block", md: "none" } }}>
					<ListItemButton id="pricing" onClick={authLogin}>
						<ListItemIcon>
							<RequestQuoteIcon />
						</ListItemIcon>
						<ListItemText>Pricing</ListItemText>
					</ListItemButton>
				</ListItem>
				<ListItem sx={{ display: { sm: "block", md: "none" } }}>
					<ListItemButton id="features" onClick={authLogin}>
						<ListItemIcon>
							<FeaturedPlayListIcon />
						</ListItemIcon>
						<ListItemText>Features</ListItemText>
					</ListItemButton>
				</ListItem>
				<ListItem sx={{ display: { sm: "block", md: "none" } }}>
					<ListItemButton id="scoring" onClick={authLogin}>
						<ListItemIcon>
							<ScoreboardIcon />
						</ListItemIcon>
						<ListItemText>Scoring</ListItemText>
					</ListItemButton>
				</ListItem>
			</List>
		);
	};

	return (
		<header className={style.nav}>
			<nav className={style.navigation}>
				<img
					className={style.logo}
					src={ScoringWizLogo}
					alt="ScoringWiz Logo"
					onClick={() => homeClick()}
				/>
				<ul className={style.nav__list}>
					{isAuthenticated && (
						<li
							className={style.nav__list_item}
							id="dashboard"
							onClick={authLogin}
						>
							Dashboard
						</li>
					)}
					<li
						className={style.nav__list_item}
						id="scoring"
						onClick={authLogin}
					>
						Scoring
					</li>
					{!isAuthenticated && (
						<li
							className={style.nav__list_item}
							id="pricing"
							onClick={authLogin}
						>
							Pricing
						</li>
					)}
					{!isAuthenticated && (
						<li
							className={style.nav__list_item}
							id="features"
							onClick={authLogin}
						>
							Features
						</li>
					)}
					<>
						<li className={style.nav__list_logo}>
							<IconButton
								id="open_drawer"
								onClick={handleClick}
								aria-controls={
									open ? "account-menu" : undefined
								}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								aria-label="Drawer with more options"
								sx={{
									height: 50,
									alignSelf: "center",
									width: 50,
								}}
							>
								<Avatar
									variant="square"
									sx={{
										width: 50,
										height: 50,
										"& img": {
											objectFit: "scale-down",
											width: "100%",
											height: "100%",
										},
									}}
									src={
										data !== undefined || null
											? `${import.meta.env.VITE_BACKEND_URL}${data?.school_logo}`
											: ""
									}
									alt="School Logo"
								/>
							</IconButton>
						</li>
						<SwipeableDrawer
							open={anchorEl}
							onClose={handleClose}
							onOpen={handleClick}
							anchor="right"
						>
							<List>
								<ListItem
									sx={{ width: "100%", display: "flex" }}
								>
									<IconButton
										id="close_drawer"
										onClick={handleClose}
										aria-controls={
											open ? "account-menu" : undefined
										}
										aria-haspopup="true"
										aria-expanded={
											open ? "true" : undefined
										}
										sx={{ marginX: "auto" }}
									>
										<Avatar
											variant="square"
											sx={{
												width: 50,
												height: 50,
												"& img": {
													objectFit: "scale-down",
													width: "100%",
													height: "100%",
												},
											}}
											src={
												data !== undefined
													? `${import.meta.env.VITE_BACKEND_URL}${
															data?.school_logo
														}`
													: ""
											}
											alt="School Logo"
										/>
									</IconButton>
								</ListItem>
								<Divider />
								{mobileScreen()}
								<ListItem>
									<ListItemButton>
										<ListItemIcon>
											<ContactSupportIcon />
										</ListItemIcon>
										<ListItemText
											onClick={() =>
												navigate("/contact-us")
											}
										>
											Contact Us
										</ListItemText>
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton>
										<ListItemIcon>
											<InfoIcon />
										</ListItemIcon>
										<ListItemText
											onClick={() =>
												navigate("/about-us")
											}
										>
											About Us
										</ListItemText>
									</ListItemButton>
								</ListItem>
								<ListItem>
									{isAuthenticated ? (
										<Button
											id="logout"
											onClick={authLogin}
											variant="contained"
											color="secondary"
											startIcon={<LogoutIcon />}
											sx={{ marginX: "auto" }}
										>
											Logout
										</Button>
									) : (
										<Button
											onClick={authLogin}
											id="login"
											variant="contained"
											color="secondary"
											startIcon={<LoginIcon />}
											sx={{
												display: "flex",
												alignSelf: "center",
												marginX: "auto",
											}}
										>
											Login
										</Button>
									)}
								</ListItem>
							</List>
						</SwipeableDrawer>
					</>
				</ul>
			</nav>
		</header>
	);
}

export default memo(NavBar);
