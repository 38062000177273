import { AthleteDatabaseType, AthletesType, Coach, CreateCoach, CricketAthleteType, FormattedAthleteType } from "@type/addAthletes";
import { athleteName, createAthlete, cricketName, updateAthlete, updateCricketData } from "@api/athletesApi";
import { activateUser, forgotPassWord, resetPasswordConfirm } from "@api/userFunctions";
import { SportChoices, UpdateSchoolInfoArgs, useAthleteNamesProps } from "@type/types";
import { coachListFunc, createCoach, handleCoachDelete } from "@api/coachList";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import { NetballStatsFetch } from "@type/NetballTypes";
import { HockeyStatsFetch } from "@type/HockeyTypes";
import { getTopPlayers } from "@api/fetchTopPlayers";
import { RugbyStatsFetch } from "@type/RugbyTypes";
import { getTopVenues } from "@api/fetchTopVenues";
import { handleDelete } from "@api/handleDelete";
import { Dispatch, SetStateAction } from "react";
import { otherSports } from "@api/otherSports";
import athleteNames from "@api/fetchAthletes";
import schoolList from "@api/getSchoolList";
import getAthletes from "@api/getAthletes";
import schoolInfo from "@api/schoolInfo";
import fetchStats from "@api/fetchStats";

import { useAuthStore } from "@store/useAuthStore";
import { createEvent, getEvent, getEvents, updateEvent } from "@api/events";
import { Event, EventFrontend } from "@type/Events";
import { useToast } from "./use-toast";
import useNavigateHook from "./useNavigateHook";


export const useStatsHook = <T extends RugbyStatsFetch | NetballStatsFetch | HockeyStatsFetch>(sport: SportChoices) : {
    data: T | undefined;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
} => {
    const { data, isLoading, error, isError } = useQuery<T>({
        queryKey: ['sportStats', sport],
        queryFn: () => fetchStats(sport) as Promise<T>,
        enabled: Boolean(sport),
    })

    return { data, isLoading, error, isError };
}

export const useSchoolInfo = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data, refetch, isLoading, isError, error } = useQuery({
        queryKey: ["schoolInfo"],
        queryFn: () => schoolInfo({ isAuthenticated }),
        enabled: !!isAuthenticated,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    });

    return { data, refetch, isLoading, isError, error };
}

export const useCoachList = () => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["coaches"],
        queryFn: () => coachListFunc(),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    if (data) {
        sessionStorage.setItem(`coaches`, JSON.stringify(data));
    }

    return { data, isLoading, isError, error };
}

export const useCoachDelete = (pk: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => handleCoachDelete(pk),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["athletes"], // Use an array for the query key
            });
            queryClient.invalidateQueries({
                queryKey: ["schoolInfo"]
            });
        },
        onError: (error: any) => {
            console.error("Error deleting coach:", error);
        },
    })
}

export const useCreateCoach = (schoolId: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (data: CreateCoach) => await createCoach(data, schoolId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["coaches"] });
        },
        onError: (error) => {
            console.error("Error creating coach:", error);
        },
    });
}

export const useUpdateSchoolInfo = () => {
    const queryClient = useQueryClient();
    const updateSchoolInfo = useAuthStore((state) => state.updateSchoolInfo)
    const mutation = useMutation({
        mutationFn: (args: UpdateSchoolInfoArgs) => updateSchoolInfo(args.id, args.formData, args.school_user, args.email), // Pass dispatch to the mutation function,
        onSuccess: (data) => {
            queryClient.setQueryData(['schoolInfo'], data);
        },
        onError: (error) => {
            console.error("Error updating school info:", error);
        },
    });

    return {
        ...mutation, // Spread the mutation object to access isLoading, isError, etc.
    };
};

export const useVerify = (options = {}) => {
    return useMutation({
        mutationFn: activateUser,
        ...options,
    });
}

export const useResetPassword = (options = {}) => {
    return useMutation({
        mutationFn: resetPasswordConfirm,
        ...options,
    })
}

export const useForgotPassword = (options = {}) => {
    return useMutation({
        mutationFn: forgotPassWord,
        ...options,
    })
}

export const useAthleteNames = ({ search, sport }: useAthleteNamesProps) => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data, isLoading } = useQuery({
        queryKey: ["athletes", search, sport],
        queryFn: (search) => athleteNames(sport, search, { isAuthenticated }),
    });

    return { data, isLoading };
}
export const useSchoolList = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data } = useQuery({
        queryKey: ["school-list"],
        queryFn: () => schoolList({ isAuthenticated }),
    });

    return { data };
}   

export const useTopPlayers = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data, isLoading, isError } = useQuery({
        queryKey: ["top-players"],
        queryFn: () => getTopPlayers(isAuthenticated),
    });

    return { data, isLoading, isError };
}

export const useTopVenues = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data, isLoading, isError } = useQuery({
        queryKey: ["top-venues"],
        queryFn: () => getTopVenues(isAuthenticated),
    });

    return { data, isLoading, isError };
}

export const useAthleteMutation = (reset: UseFormReset<AthletesType>, setValue: UseFormSetValue<AthletesType>) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (formattedData: FormattedAthleteType) => createAthlete(formattedData),
        onSuccess: () => {
            reset();
            setValue('name', "");
            setValue("cricket", false);
            setValue("rugby", false);
            setValue("netball", false);
            setValue("tennis", false);
            setValue("hockey", false);
            setValue("date_of_birth.day", "");
            setValue("date_of_birth.month", "");
            setValue("date_of_birth.year", "");
            setValue('gender', undefined);
            queryClient.invalidateQueries({
                queryKey: ["athletes"], // Use an array for the query key
            });
        },
        onError: (error) => {
            console.error(error);
        },
    })

    return { mutation };
}

export const useUpdateAthleteMutation = (reset: UseFormReset<AthletesType>, athleteData: AthleteDatabaseType | null, setEditActive: Dispatch<SetStateAction<boolean>>) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (formattedData: AthleteDatabaseType) => updateAthlete(formattedData, athleteData, setEditActive),
        onSuccess: () => {
            reset();
            queryClient.invalidateQueries({
                queryKey: ["athletes"], // Use an array for the query key
            });
        },
    });

    return { mutation };
}

export const useIndividualAthlete = ({id, setValue} : {id: number, setValue: UseFormSetValue<AthletesType>}) => {
    const { data, isLoading, isError, error } = useQuery<AthleteDatabaseType>({
        queryKey: ["athletes", id],
        queryFn: () => athleteName(id, setValue),
        enabled: !!id,
    });

    return { data, isLoading, isError, error };
}

export const useIndividualCricket = ({id, setValue} : {id: number, setValue: UseFormSetValue<CricketAthleteType>}) => {
    const { data, isLoading, isError, error } = useQuery<CricketAthleteType>({
        queryKey: ["cricket", id],
        queryFn: () => cricketName(id, setValue),
        enabled: !!id,
    });

    return { data, isLoading, isError, error };
}

export const useCricketUpdate = (id: number, setEditActive: Dispatch<SetStateAction<boolean>>) => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
		mutationFn: (data: CricketAthleteType) => updateCricketData(data, id),
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey : ["cricket"]});
			setEditActive(false);
		},
        onError: (error: unknown) => {
            if (error instanceof Error) {
                throw new Error(error.message);
            }
    }
	});

    return { mutation };
}

export const useAthleteNameSearch = (sport: "all" | "cricket") => {
    const { isLoading, isError, error, data, refetch } = useQuery({
        queryKey: [sport],
        queryFn: () => getAthletes(sport), 
        refetchOnMount: false,
        refetchOnWindowFocus: false, 
    })

    return { data, isLoading, isError, error, refetch };
}

export const useDeleteAthlete = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (id: number) => handleDelete(id),
        onSuccess: async () => {
            // Invalidate and refetch in sequence
            await queryClient.invalidateQueries({ queryKey: ["athletes"] });
            await queryClient.invalidateQueries({ queryKey: ["cricket"] });
        },
        // Add error handling
        onError: (error) => {
            console.error("Failed to delete athlete:", error);
            // You might want to show an error toast/notification here
        }
    });
    return { mutation };
}

export const useOtherSports = (id: string) => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data, isLoading, isError } = useQuery({
        queryKey: ['otherSports', id],
        queryFn: () => otherSports(isAuthenticated, id),
    });

    return { data, isLoading, isError };
}

export const useEvents = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const { data, isLoading, isError } = useQuery<Event[] | null>({
        queryKey: ['events'],
        queryFn: () => getEvents(isAuthenticated),
        enabled: isAuthenticated, // Only fetch if user is authenticated
        retry: 1,
    });

    return { data, isLoading, isError };
}

export const useEvent = (id: number | string) => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const eventId = typeof id === "string" ? Number.parseInt(id, 10) : id
    const { data, isLoading, isError, error } = useQuery<Event | null>({
        queryKey: ['events', eventId],
        queryFn: () => getEvent(isAuthenticated, eventId),
        enabled: isAuthenticated, // Only fetch if user is authenticated
        retry: 1,
    });

    return { data, isLoading, isError, error };
}

export const useCreateEvent = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const queryClient = useQueryClient();
    const { toast } = useToast();
    const { goToPage } = useNavigateHook();
    return useMutation({
        mutationFn: (data: EventFrontend) => createEvent(isAuthenticated, data),
        onSuccess: () => {
        // Invalidate events query to refetch the list
        queryClient.invalidateQueries({ queryKey: ["events"] })

        // Show success toast
        toast({
            title: "Event Created",
            description: "Your event has been successfully created.",
        })

        // Redirect to events list
        goToPage("/dashboard/events/all")
        },
        onError: (error) => {
        // Display server error
        toast({
            variant: "destructive",
            title: "Error",
            description: "There was an unknown error creating your event.",
        })
    }})
}

type EditProps = {
    eventId: string;
    data: EventFrontend
}

export const useEditEvent = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const queryClient = useQueryClient();
    const { toast } = useToast();
    const { goToPage } = useNavigateHook();
    return useMutation({
        mutationFn: ({eventId, data}: EditProps) => updateEvent(isAuthenticated, eventId, data),
        onSuccess: () => {
        // Invalidate events query to refetch the list
        queryClient.invalidateQueries({ queryKey: ["events"] })

        // Show success toast
        toast({
            title: "Event Updated",
            description: "Your event has been successfully Updated.",
        })

        // Redirect to events list
        goToPage("/dashboard/events/all")
        },
        onError: (error) => {
        // Display server error
        toast({
            variant: "destructive",
            title: "Error",
            description: "There was an unknown error updating your event.",
        })
    }})
}