import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@/lib/utils";
import * as React from "react";

const buttonVariants = cva(
	"inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
	{
		variants: {
			variant: {
				default:
					"bg-primary text-primary-foreground shadow-sm hover:bg-primary/90 hover:text-primary hover:bg-primary-foreground uppercase hover:cursor-pointer",
				destructive:
					"bg-destructive text-destructive-foreground shadow-xs hover:bg-destructive/90 uppercase hover:cursor-pointer",
				outline:
					"border border-input bg-background shadow-xs hover:bg-accent hover:text-primary-dark uppercase hover:cursor-pointer",
				secondary:
					"bg-secondary text-secondary-foreground shadow-xs hover:bg-secondary-foreground hover:text-secondary uppercase hover:cursor-pointer",
				ghost: "hover:bg-accent hover:text-accent-foreground uppercase hover:cursor-pointer",
				link: "text-primary underline-offset-4 hover:underline uppercase",
				rugby: "bg-primary-dark uppercase shadow-sm rounded-full text-primary-foreground hover:bg-primary-foreground hover:text-primary-dark hover:cursor-pointer",
				tennis: "bg-primary uppercase shadow-sm text-primary-foreground hover:bg-primary-foreground hover:text-primary-dark hover:cursor-pointer",
				hockey: "bg-primary uppercase shadow-sm text-primary-foreground hover:bg-primary-foreground hover:text-primary-dark hover:cursor-pointer",
				cricket:
					"bg-primary uppercase shadow-sm text-primary-foreground hover:bg-primary-dark hover:text-primary-foreground hover:cursor-pointer",
				netball:
					"bg-netball text-primary-foreground shadow-sm hover:bg-netball-dark uppercase hover:cursor-pointer",
				success:
					"bg-green-500 text-primary-foreground hover:bg-green-600 uppercase shadow-sm hover:cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed",
				danger: "bg-red-500 text-primary-foreground hover:bg-red-600 uppercase shadow-sm hover:cursor-pointer",
			},
			size: {
				default: "h-9 px-4 py-2",
				sm: "h-8 rounded-md px-3 text-xs",
				lg: "h-10 rounded-md px-8",
				icon: "size-9 rounded-full",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.memo(
	React.forwardRef<HTMLButtonElement, ButtonProps>(
		(
			{ className, variant, size, asChild = false, onClick, ...rest },
			ref
		) => {
			const Comp = asChild ? Slot : "button";
			const classes = React.useMemo(
				() => buttonVariants({ variant, size, className }),
				[variant, size, className]
			);
			return (
				<Comp
					className={cn(classes)}
					ref={ref}
					{...(onClick ? { onClick } : {})}
					{...rest}
				/>
			);
		}
	)
);

Button.displayName = "Button";

export { Button, buttonVariants };
