import { AthleteDatabaseType, AthletesType, CricketAthleteType, FormattedAthleteType } from '@type/addAthletes';
import { databaseToFormDate } from '@pages/dashboard/athletes/utils/dobCalc';
import { UseFormSetValue } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import Cookie from 'js-cookie';
import axios from 'axios';


export const createAthlete = async (data: FormattedAthleteType) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
        },
        };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/`,
            data,
            config
        );
        return response.data; // Return the created data from the API response
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
            // Provide user feedback for submission error, e.g., show an error message to the user
        }
    }
};

export const updateAthlete = async (data: AthleteDatabaseType, athleteData: AthleteDatabaseType | null, setEditActive: Dispatch<SetStateAction<boolean>>) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
        },
    };

    try {
        await axios.put(
            `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/${
            athleteData?.id
            }/`,
            data,
            config
    );
        setEditActive(false);
    } catch (error) {
        console.error(error);
    }
}

export const deleteAthlete = async (id: number) => {

}

export const athleteName = async (athleteId: number, setValue: UseFormSetValue<AthletesType>) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
        },
    };

    const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/${athleteId}/`,
        config
    );
    const individualData = response.data;

    const dateObj = databaseToFormDate(individualData.date_of_birth);

    setValue("name", individualData.name);
    setValue("surname", individualData.surname);
    setValue("gender", individualData.gender);
    setValue("race", individualData.race);
    setValue("date_of_birth", dateObj);
    setValue("height", individualData.height);
    setValue("weight", individualData.weight);
    setValue("cricket", individualData.cricket);
    setValue("rugby", individualData.rugby);
    setValue("tennis", individualData.tennis);
    setValue("netball", individualData.netball);
    setValue("hockey", individualData.hockey);

    return response.data;
}

export const cricketName = async (athleteId: number, setValue: UseFormSetValue<CricketAthleteType>) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${Cookie.get("access")}`,
            Accept: "application/json",
        },
    };

    const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/athlete/cricket/${athleteId}/`,
        config
    );
    const singleData = response.data;
    setValue("batting_style", singleData.batting_style);
    setValue("bowling_style", singleData.bowling_style);
    setValue("bowling_type", singleData.bowling_type);

    return singleData;
}

export const updateCricketData = async (data: CricketAthleteType, id: number) => {
		const newData = {
			batting_style: data.batting_style,
			bowling_style: data.bowling_style,
			bowling_type: data.bowling_type,
		};
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `JWT ${Cookie.get("access")}`,
				Accept: "application/json",
			},
		};

		try {
			await axios.put(
				`${import.meta.env.VITE_BACKEND_URL}/athlete/cricket/${id}/update/`,
				newData,
				config
			);
		} catch (error) {
			console.error(error);
		}
	};

export const athletesNames = async () => {

}